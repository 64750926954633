import React from "react";

import { useStaticQuery, graphql } from "gatsby";

// Components
import { BlogsData, HeroImage } from "@components";

const Blogs = () => {
    const {
        allStrapiBlog: { nodes: blogs },
        placeholder,
    } = useStaticQuery(
        graphql`
            query {
                allStrapiBlog {
                    nodes {
                        slug
                        headline
                        publishedAt
                        content {
                            data {
                                childMarkdownRemark {
                                    rawMarkdownBody
                                    html
                                }
                            }
                            medias {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: WEBP
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                                alternativeText
                                url
                                file {
                                    alternativeText
                                }
                            }
                        }
                    }
                }

                placeholder: file(
                    relativePath: { eq: "blogPlaceholder.webp" }
                ) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );


    return (
        <>
            <HeroImage
                title={"Our Blogs"}
                image={placeholder.childImageSharp.fluid}
            />
            {blogs && <BlogsData blogs={blogs} />}
        </>
    );
};

export default Blogs;
